import React, { useState, useEffect } from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import EventCard from './EventCard';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function EventGroup() {
	const [events, setEvents] = useState([]);
	useEffect(() => {
		//API GET all events
		setEvents([{
			id: 0,
			title: "League",
			description: "大司",
			time: 1694387913,
			min_gezhi: 0,
			min_user_count: 5,
			max_user_count: 5,
			current_user_count: 3
		},
		{
			id: 1,
			title: "League",
			description: "马飞",
			time: 1694387913,
			min_gezhi: 0,
			min_user_count: 5,
			max_user_count: 5,
			current_user_count: 3
		},
		{
			id: 2,
			title: "League",
			description: "BZZB",
			time: 1694387913,
			min_gezhi: 0,
			min_user_count: 5,
			max_user_count: 5,
			current_user_count: 3
		}
		]);
	}, [])
	return (
		<Container fluid>
			<Row>
				<Col>
					{
						events.length>0 ? events.map((item) => {
							return <EventCard eventData={item}></EventCard>
						}) : null
					}
				</Col>
			</Row>
		</Container>


	);
}
export default EventGroup;