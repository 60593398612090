import React from 'react';
import './App.css';
import Card from "react-bootstrap/Card";
import EventDetail from './EventDetail';
import "bootstrap/dist/css/bootstrap.min.css";

function EventCard(props) {
	const date = new Date(props.eventData.time*1000)
	const time = date.toTimeString();
	return (
		<div>
			<Card style={{ width: '18rem' }}>
				<Card.Body>
					<Card.Title>{props.eventData.title}</Card.Title>
					<Card.Text>
						<p>Event Time: {time}</p>
						<p>Description: {props.eventData.description}</p>
						<p>Event Size: {props.eventData.current_user_count}/{props.eventData.max_user_count}</p>
						
					</Card.Text>
					<EventDetail eventData={props.eventData} />
				</Card.Body>
			</Card>
		</div>
	);
}
export default EventCard;