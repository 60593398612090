import React, { useState } from 'react';
import './App.css';
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "bootstrap/dist/css/bootstrap.min.css";

function LeftSlider() {
    const [show, setShow] = useState(false);
    const [user,setUser] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => {
        //api get user
        setUser({userName:"卢本伟",gezhi:5});
        setShow(true);
    }
    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                User Profile
            </Button>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{user.userName}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>Gezhi: {user.gezhi}</p>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}
export default LeftSlider;
