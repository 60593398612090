import React, { useState } from 'react';
import './App.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import "bootstrap/dist/css/bootstrap.min.css";

function CreationForm() {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	const [eventData, setEventData] = useState({ title: "", description: "", min_user_count: 0, max_user_count: 0, min_gezhi: 0 });
	const inputChanged = (e) => {
		console.log("input event", e.target);
		setEventData({ ...eventData, [e.target.name]: e.target.value });
	}
	const create = () => {
		console.log(eventData);
	}
	return (
		<div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Title</Form.Label>
							<Form.Control type="text" onChange={inputChanged} name="title" />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Description</Form.Label>
							<Form.Control as="textarea" rows={3} onChange={inputChanged} name="description" />
						</Form.Group>
						<InputGroup className="mb-3">
							<InputGroup.Text>Min and Max # of participants</InputGroup.Text>
							<Form.Control onChange={inputChanged} name="min_user_count" />
							<Form.Control onChange={inputChanged} name="max_user_count" />
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Minimum Gezhi</InputGroup.Text>
							<Form.Control onChange={inputChanged} name="min_gezhi" />
						</InputGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={create}>
						开整
					</Button>
				</Modal.Footer>
			</Modal>
			<Button type="button" class="btn btn-primary float-end align-middle" onClick={() => setShow(true)}>宇宙万法的源头</Button>
		</div>


	);
}
export default CreationForm;