import React, { useState } from 'react';
import './App.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

function EventDetail(props) {
	console.log(props.eventData);
	const [showDetails, setShowDetails] = useState(false);
	const [eventData, setEventData] = useState({})
	const handleClose = () => setShowDetails(false);
	const handleRegister = () => { console.log("如来") };
	const handleOpen = () => {
		//API: get event by eventId
		setEventData({
			id: 0,
			title: "Leagaue",
			description: "大司",
			time: 114514,
			min_gezhi: 0,
			min_user_count: 5,
			max_user_count: 5,
			current_user_count: 3
		},);
		setShowDetails(true);
	}
	return (
		<div>
			<Button variant="primary" style={{ float: "right" }} onClick={handleOpen}>Details</Button>
			<Modal show={showDetails} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{eventData.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Description: {eventData.description}</p>
					<p>Min Participant: {eventData.min_user_count}</p>
					<p>Max Participant: {eventData.max_user_count}</p>
					<p>Current 如来: {eventData.current_user_count}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleRegister}>
						如来
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
export default EventDetail;