import React from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import LeftSlider from './LeftSlider';
import EventGroup from './EventGroup';
import CreationForm from './CreationForm';
import Stack from "react-bootstrap/Stack";
function App() {

	return (
		<div>
			<div class="container-fluid py-2">
				<Stack direction="horizontal" gap={3}>
					<span class="text-light h1 align-middle">如来</span>
					<input type="text" class="short form-control p-2 ms-auto" placeholder="Search for 活儿"></input>
					<CreationForm ></CreationForm>
				</Stack>
			</div>
			<div class="container py-5">
				<div class="row">
					<div class="col">
						<EventGroup></EventGroup>
					</div>
				</div>
			</div>
			<div class="footer">
				<LeftSlider username="Example User" />
			</div>
		</div>
	);
}
export default App;